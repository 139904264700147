<ng-container *ngIf="item && isSimpleType">
  <lhc-input [item]="item"></lhc-input>
  <!-- single unit -->
  <div class="lhc-de-unit" *ngIf="hasSingleUnit">
    <input
      [disabled]="item._readOnly"
      type="text"
      id="{{unitElementId}}"
      value="{{item.unit.name||item.unit.code}}"
      aria-labelledby="th_Units"
      readonly
      (blur)="lhcDataService.activeRowOnBlur(item)"
      (focus)="lhcDataService.setActiveRow(item)"
    >
  </div>
</ng-container>
