<ng-container *ngIf="item">
  <nz-time-picker 
    [nzDisabled]="item._readOnly"
    id="{{item._elementId}}"
    class="lhc-time-picker"
    nzFormat="HH:mm:ss"
    nzPlaceHolder="{{item._placeholder}}"
    nzSize="small"
    [ngModel]="time"
    (ngModelChange)="onModelChange($event)"
    (focus)="lhcDataService.setActiveRow(item)"
    attr.aria-required="{{ item._answerRequired }}"
  >
  </nz-time-picker>
</ng-container>

