<!-- unit list -->
<ng-container *ngIf="hasUnitAutocomplete">
  <lhc-autocomplete 
    [options]="options" 
    [item]="item"
    [(dataModel)]="item.unit"
    (onBlurFn)="lhcDataService.activeRowOnBlur(item)"
    (onFocusFn)="lhcDataService.setActiveRow(item)"
    [isFormReady]="lhcDataService.isFormReady()"
    ></lhc-autocomplete>
</ng-container>
<ng-container  *ngIf="!hasUnitAutocomplete">
  <input type="text"
   [readOnly]="options.readOnly"
   placeholder="{{options.toolTip}}"
   id="{{options.elementId}}"
   value="{{item?.unit?.name||item?.unit?.code}}"
   (blur)="lhcDataService.activeRowOnBlur(item)"
   (focus)="lhcDataService.setActiveRow(item)"
   (change)="updateModel($event)">
</ng-container>
