<div *ngIf="options" class="{{options.readOnly ? 'lhc-item-readyonly' : null}}">
  <input type="text"
         [disabled]="options.readOnly"
         placeholder="{{options.placeholder}}"
         id="{{options.elementId}}"
         (blur)="onInputBlur()"
         (focus)="onInputFocus()"
         #ac
  >
</div>

