<ng-container *ngIf="item" [ngSwitch]="item.displayControl.questionLayout">
  <ng-container *ngSwitchCase="'horizontal'">
    <lhc-group-horizontal [item]="item"></lhc-group-horizontal>
  </ng-container>
  <ng-container *ngSwitchCase="'matrix'">
    <lhc-group-matrix [item]="item"></lhc-group-matrix>
  </ng-container>
  <ng-container *ngSwitchCase="'vertical'">
    <lhc-group-vertical [item]="item"></lhc-group-vertical>
  </ng-container>
</ng-container>
