<nz-checkbox-wrapper style="width: 100%;"  (nzOnChange)="onCheckboxModelChange($event)" *ngIf="item">
  <div nz-row [ngClass]="{'lhc-vertical': commonUtils.getDisplayControlIsVertical(item.displayControl)}">
    <div *ngFor="let answer of acOptions.listItems; index as i">
      <label 
        nz-checkbox 
        attr.aria-label="Checkbox {{answer._displayText}} for {{item.question}}"
        (click)="lhcDataService.setActiveRow(item)"
        [nzValue]="item.answers[i]" 
        [(ngModel)]="checkboxModels[i]"
        [nzDisabled]="item._readOnly"
        [id]="lhcDataService.getItemAnswerId(item, answer)">
        {{answer._displayText}}
      </label>
    </div>
    <div *ngIf="item.dataType=== 'CWE'">
      <label 
        nz-checkbox 
        attr.aria-label="Checkbox Other for {{item.question}}"
        (click)="lhcDataService.setActiveRow(item)"
        [nzValue]="{ 'text': this.otherValue, '_notOnList': true}"
        [nzDisabled]="item._readOnly"
        [(ngModel)]="otherCheckboxModel" 
        [id]="item._elementId + '_other'">
        Other
      </label>
      <input 
        type="text" 
        [ngModel]="otherValue" 
        (ngModelChange)="onOtherValueChange($event)" 
        class="lhc-answer-other-input" 
        [id]="item._elementId + '_otherValue'" 
        nz-input
        *ngIf="otherCheckboxModel" 
        [disabled]="item._readOnly"
        (focus)="lhcDataService.setActiveRow(item)"/>
    </div>
  </div>
</nz-checkbox-wrapper>


